<template>
  <div>
    <portal to="page-top-title">Feedback</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'feedbacks-new' }" icon="fa-plus" text>Add New</btn>
    </portal>

    <box-table title="List" url="/feedbacks" :url-query="query" data-prop="feedbacks" :dataCallback="dataCallback" loader @request-after="requestAfter">
      <template slot="table-header">
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Event</th>
          <th>Action</th>
          <th>Api</th>
          <th>Offer</th>
          <th class="text-center">Active</th>
          <th>Stats</th>
          <th class="text-right">Created At</th>
          <th class="text-right"></th>
        </tr>
      </template>
      <template slot="table-body">
        <table-row v-for="feedback in feedbacks" :key="`feedback-tr-${feedback.id}`" :value="feedback">
          <td>{{ feedback.id }}</td>
          <td>{{ feedback.name }}</td>
          <td>{{ feedback.event_text }}</td>
          <td>{{ feedback.action_text }}</td>
          <td>
            <nullable :value="feedback.settings.api">
              <template slot="value">
                <router-link v-if="feedback.settings.api" :to="{ name: 'apis-single', params: { id: feedback.settings.api.id } }">{{ feedback.settings.api.name }}</router-link>
              </template>
            </nullable>
          </td>
          <td>
            <nullable :value="feedback.settings.offer">
              <template slot="value">
                <router-link v-if="feedback.settings.offer" :to="{ name: 'offers-single', params: { id: feedback.settings.offer.id } }">{{ feedback.settings.offer.name }}</router-link>
              </template>
            </nullable>
          </td>
          <td class="text-center">
            <badge-active :value="feedback.active"/>
          </td>
          <td>
            <stats-column :stats="feedback.stats"/>
          </td>
          <td class="text-right">{{ feedback.created_at }}</td>
          <td class="text-right">
            <template v-if="feedback.active">
              <btn icon="fa-ban" color="danger" @click.stop.native="confirmAction(feedback.id, 'deactivate')">Deactivate</btn>
            </template>
            <template v-else>
              <btn icon="fa-check" color="success" @click.stop.native="confirmAction(feedback.id, 'activate')">Activate</btn>
            </template>
            <btn v-if="feedback.metadata.editable.this" :route="{ name: 'feedbacks-edit', params: { id: feedback.id } }" icon="fa-edit">Edit</btn>
            <btn :route="{ name: 'feedbacks-single', params: { id: feedback.id } }" color="info" icon="fa-eye">Show</btn>
          </td>
        </table-row>
      </template>
    </box-table>
  </div>
</template>

<script>
import BoxTable from '@/views/components/data/BoxTable'
import Btn from '@/views/components/simple/Btn'
import Nullable from '@/views/components/simple/Nullable'
import BadgeActive from '@/views/components/simple/BadgeActive'
import StatsColumn from '@/views/components/stats/StatsColumn'
import Action from '@/modules/utils/Action'

export default {
  name: 'FeedbacksList',
  metaInfo: {
    title: 'Feedbacks'
  },
  components: {
    BadgeActive,
    BoxTable,
    Btn,
    Nullable,
    StatsColumn
  },
  data () {
    return {
      query: {
        with: [
          'settings.api',
          'settings.offer'
        ]
      },
      feedbacks: []
    }
  },
  methods: {
    dataCallback (data) {
      return data.map((item) => { item.stats = undefined; return item })
    },
    requestAfter () {
      this.loadFeedbackStats(
        this.feedbacks.map((feedback) => feedback.id)
      )
    },
    loadFeedbackStats (ids) {
      this.$http
        .get('/feedbacks/stats', { params: { ids: ids } })
        .then((res) => {
          const { data: { data } } = res

          this.feedbacks.forEach((feedback) => {
            feedback.stats = data.find((stats) => stats.id === feedback.id)
          })
        })
    },
    confirmAction (id, action) {
      Action.confirm({
        id: id,
        action: action,
        name: 'Feedback',
        route: '/feedbacks/:id/action',
        collection: this.feedbacks
      })
    }
  }
}
</script>
